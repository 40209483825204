.carousel {
  display: flex;
  overflow-x: scroll;
}

.carousel img {
  flex: 0 0 auto;

  width: 100%;
  height: auto;
  margin-right: 10px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.car-detail-carousel .swiper-button-next, .car-detail-carousel .swiper-button-prev {
  color: white !important;
  height: calc(100% - 2px) !important;
  top: 23px !important;
  padding: 0 24px;
  background-color: #282F75;
  opacity: 0.6;
}

.car-detail-carousel .swiper-button-next {
  right: 0 !important;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.car-detail-carousel .swiper-button-prev {
  left: 0 !important;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 32px !important;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.damage-carousel-swiper {
  height: 80%;
  width: 100%;
}

.damage-carousel-swiper-bottom {
  height: min-content;
  box-sizing: border-box;
  padding: 10px 0;
}

.damage-carousel-swiper-bottom .swiper-slide {
  width: 25%;
  height: 100%;
}

.damage-carousel-swiper-bottom .swiper-slide-active {
  opacity: 1;
}

.damage-carousel-swiper-bottom .swiper-slide-thumb-active {
  border: 1px solid black;
}

.swiper-pagination {
  position: absolute;
  bottom: 0;
  background: #00000094;
  width: fit-content !important;
  padding: 5px;
  border-radius: 5px;
  color: white !important;
  margin: auto;
  left: 0;
  right: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
