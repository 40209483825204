::-webkit-scrollbar {
  width: 2px;
  height: 20px;
}

::-webkit-scrollbar-thumb:vertical {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #535353;
  background: white;
}
::-webkit-scrollbar-track:vertical {
  -webkit-box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

.point {
  position: absolute;
  display: none;
  width: 10px;
  height: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #282f75;
  cursor: pointer;
}

.point._1 {
  left: 117px;
  top: 58px;
  right: auto;
  bottom: auto;
}

.point._2 {
  left: 104px;
  top: 78px;
  right: auto;
  bottom: auto;
}

.point._3 {
  left: 100px;
  top: 100px;
  right: auto;
  bottom: auto;
}

.point._4 {
  left: 103px;
  top: 125px;
  right: auto;
  bottom: auto;
}

.point._5 {
  left: 117px;
  top: 152px;
  right: auto;
  bottom: auto;
}

.point._6 {
  left: 160px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point._7 {
  left: 215px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point._8 {
  left: 260px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point._9 {
  left: 300px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point._10 {
  left: 355px;
  top: 58px;
  right: auto;
  bottom: auto;
}

.point._11 {
  left: 362px;
  top: 100px;
  right: auto;
  bottom: auto;
}

.point.es2 {
  left: 355px;
  top: 144px;
  right: auto;
  bottom: auto;
}

.point._13 {
  left: 300px;
  top: 154px;
  right: auto;
  bottom: auto;
}

.point._14 {
  left: 260px;
  top: 155px;
  right: auto;
  bottom: auto;
}

.point._15 {
  left: 215px;
  top: 155px;
  right: auto;
  bottom: auto;
}

.point._16 {
  left: 160px;
  top: 155px;
  right: auto;
  bottom: auto;
}

.point._17 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 235px;
}

.point._18 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 173px;
}

.point._19 {
  left: 145px;
  top: auto;
  right: auto;
  bottom: 195px;
}

.point._20 {
  left: 57px;
  top: auto;
  right: auto;
  bottom: 195px;
}

.point._18 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 173px;
}

.point._18 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 183px;
}

.point._21 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 88px;
}

.point._22 {
  left: 146px;
  top: auto;
  right: auto;
  bottom: 93px;
}

.point._23 {
  left: 57px;
  top: auto;
  right: auto;
  bottom: 93px;
}

.point.es1 {
  left: 362px;
  top: 100px;
  right: auto;
  bottom: auto;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.point.es3 {
  left: 300px;
  top: 154px;
  right: auto;
  bottom: auto;
}

.point.es4 {
  left: 260px;
  top: 155px;
  right: auto;
  bottom: auto;
}

.point.es5 {
  left: 215px;
  top: 155px;
  right: auto;
  bottom: auto;
}

.point.es6 {
  left: 160px;
  top: 155px;
  right: auto;
  bottom: auto;
}

.point.es7 {
  left: 117px;
  top: 152px;
  right: auto;
  bottom: auto;
}

.point.es8 {
  left: 103px;
  top: 125px;
  right: auto;
  bottom: auto;
}

.point.es9 {
  left: 100px;
  top: 100px;
  right: auto;
  bottom: auto;
}

.point.es10 {
  left: 104px;
  top: 78px;
  right: auto;
  bottom: auto;
}

.point.es11 {
  left: 117px;
  top: 58px;
  right: auto;
  bottom: auto;
}

.point.es12 {
  left: 160px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point.es13 {
  left: 215px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point.es14 {
  left: 260px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point.es15 {
  left: 300px;
  top: 54px;
  right: auto;
  bottom: auto;
}

.point.es16 {
  left: 355px;
  top: 58px;
  right: auto;
  bottom: auto;
}

.point.es25 {
  left: 145px;
  top: auto;
  right: auto;
  bottom: 195px;
}

.point.es26 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 183px;
}

.point.es27 {
  left: 57px;
  top: auto;
  right: auto;
  bottom: 195px;
}

.point.es28 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 235px;
}

.point.es35 {
  left: 146px;
  top: auto;
  right: auto;
  bottom: 93px;
}

.point.es36 {
  left: 102px;
  top: auto;
  right: auto;
  bottom: 88px;
}

.point.es37 {
  left: 57px;
  top: auto;
  right: auto;
  bottom: 93px;
}

.point.es17 {
  left: auto;
  top: auto;
  right: 50px;
  bottom: 192px;
}

.point.es18 {
  left: auto;
  top: auto;
  right: 98px;
  bottom: 192px;
}

.point.es18 {
  left: auto;
  top: auto;
  right: 98px;
  bottom: 192px;
}

.point.es19 {
  left: auto;
  top: auto;
  right: 142px;
  bottom: 192px;
}

.point.es19 {
  left: auto;
  top: auto;
  right: 144px;
  bottom: 192px;
}

.point.es20 {
  left: auto;
  top: auto;
  right: 197px;
  bottom: 192px;
}

.point.es21 {
  left: auto;
  top: auto;
  right: 254px;
  bottom: 192px;
}

.point.es22 {
  left: auto;
  top: auto;
  right: 300px;
  bottom: 192px;
}

.point.es23 {
  left: auto;
  top: auto;
  right: 204px;
  bottom: 231px;
}

.point.es24 {
  left: auto;
  top: auto;
  right: 110px;
  bottom: 231px;
}

.point.es29 {
  left: auto;
  top: auto;
  right: 50px;
  bottom: 70px;
}

.point.es30 {
  left: auto;
  top: auto;
  right: 95px;
  bottom: 82px;
}

.point.es31 {
  left: auto;
  top: auto;
  right: 153px;
  bottom: 82px;
}

.point.es32 {
  left: auto;
  top: auto;
  right: 209px;
  bottom: 82px;
}

.point.es33 {
  left: auto;
  top: auto;
  right: 250px;
  bottom: 89px;
}

.point.es34 {
  left: auto;
  top: auto;
  right: 299px;
  bottom: 82px;
}

.point.is1 {
  left: auto;
  top: auto;
  right: 19px;
  bottom: 97px;
}

.point.is2 {
  left: auto;
  top: auto;
  right: 19px;
  bottom: 44px;
}

.point.is3 {
  left: auto;
  top: auto;
  right: 66px;
  bottom: 44px;
}

.point.is4 {
  left: auto;
  top: auto;
  right: 66px;
  bottom: 98px;
}

.point.is5 {
  left: auto;
  top: auto;
  right: 147px;
  bottom: 158px;
}

.point.is6 {
  left: auto;
  top: auto;
  right: 207px;
  bottom: 81px;
}

.point.is7 {
  left: auto;
  top: auto;
  right: 340px;
  bottom: 81px;
}

.point.is8 {
  left: auto;
  top: auto;
  right: 375px;
  bottom: 127px;
}

.point.is9 {
  left: auto;
  top: auto;
  right: 340px;
  bottom: 173px;
}

.point.is10 {
  left: auto;
  top: auto;
  right: 207px;
  bottom: 173px;
}

.point.is11 {
  left: auto;
  top: auto;
  right: 162px;
  bottom: 24px;
}

.point.is12 {
  left: auto;
  top: auto;
  right: 251px;
  bottom: 2px;
}

.point.is13 {
  left: auto;
  top: auto;
  right: 315px;
  bottom: 7px;
}
