.carousel-root {
  width: 100%;
}

.carousel-root,
.carousel-wrapper,
.carousel,
.carousel > .slider-wrapper,
.carousel > .slider-wrapper > .slider {
  height: 100%;



}

.image-container {

margin-left: 150px;
  height: 60%;
  width: 60%;

}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;

}

.carousel .control-next.control-arrow:hover  { background-color: transparent;}
.carousel .control-prev.control-arrow:hover {background-color: transparent; }

.carousel {
  display: flex;
  overflow-x: scroll;
}

.carousel img {
  flex: 0 0 auto;

  width: 100%;
  height: auto;
  margin-right: 10px;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.damage-carousel-swiper {
  height: 80%;
  width: 100%;
}

.damage-carousel-swiper-bottom {
  height: min-content;
  box-sizing: border-box;
  padding: 10px 0;
}

.damage-carousel-swiper-bottom .swiper-slide {
  width: 25%;
  height: 100%;
}

.damage-carousel-swiper-bottom .swiper-slide-active {
  opacity: 1;
}

.damage-carousel-swiper-bottom .swiper-slide-thumb-active {
  border: 1px solid black;
}

.swiper-pagination {
  position: absolute;
  bottom: 0;
  background: #00000094;
  width: fit-content !important;
  padding: 5px;
  border-radius: 5px;
  color: white !important;
  margin: auto;
  left: 0;
  right: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  height: auto;
  display: flex;
}

.damage-carousel-swiper .swiper-slide {
  height: auto;
  background-color: transparent;
}