.video-recording-icon {
  width: 24px;
  height: 24px;
  position: relative;
}

.recording circle:nth-child(1) {
  transition: stroke-dasharray 1s linear;
  stroke-dasharray: 25, 100;
}

.recording circle:nth-child(2) {
  transform-origin: center;
  animation: pulse 1s infinite;
}

.recording circle:nth-child(3) {
  transform-origin: center;
  animation: pulse 1s infinite 0.33s;
}

.recording circle:nth-child(4) {
  transform-origin: center;
  animation: pulse 1s infinite 0.66s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
